.dbCard {
  background-color: var(--mb-color-bg-white);

  &:hover {
    color: var(--mb-color-brand);
  }
}

.addCard {
  background-color: var(--color-bg-medium);

  &:hover {
    background-color: var(--mb-color-brand);
    color: var(--color-text-white);
  }
}
